import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import AnalyticsScreen from './AnalyticsScreen'
import AnalyticsScreens from './AnalyticsScreens'
import Analytic17Screen from './Analytic17Screen'

const Stack = createStackNavigator();

export const AnalyticsStack = () => {
  return (
    <Stack.Navigator>

      <Stack.Screen
        options={{headerShown: false}}
        name={AnalyticsScreens.AnalyticsScreen}
        component={AnalyticsScreen}
      />
      <Stack.Screen
        options={{headerShown: false}}
        name={AnalyticsScreens.Analytic17}
        component={Analytic17Screen}
      />
    </Stack.Navigator>
  );
};
