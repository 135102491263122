import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native'
import React, { useMemo, useState } from 'react'
import { ActivityIndicator, Switch, Text, TextInput, View } from 'react-native'
import { t } from 'i18n-js'
import getTheme from '../../../constants/Colors'
import OptionalDate from '../../../components/OptionalDate'
import HelpIconButton from '../../../components/HelpIconButton/HelpIconButton'
import FlatChoose from '../../../components/FlatChoose'
import { useDispatch, useSelector } from 'react-redux'
import { Reducer } from '../../../store/reducers'
import { getAsipCostLevels } from '../util'
import {
  setAnalytic17ConfigAllOpen,
  setAnalytic17ConfigPriceList,
  setAnalytic17ConfigShowContract,
  setAnalytic17ConfigUseBomEngineering,
  setAnalytic17ConfigUseBomExternalCosts,
  setAnalytic17ConfigUseBomSpecialMaterial,
  setAnalytic17ConfigUseHoursCost,
} from '../../../store/action/settingsAction'
import {
  KeyboardAwareScrollView,
} from 'react-native-keyboard-aware-scroll-view'
import Button from '../../../components/Button'
import { downloadAnalytic17 } from './downloadAnalytic17'

const getAnalytic17HourTypeText = (i: number) => {
  switch (i) {
    case -1:
      return t('BILL_OF_MATERIALS')
    case -2:
      return t('EMPLOYEE_COST')
    default:
      return t('CUSTOM')
  }
}

const Analytic17Screen = () => {

  const theme = getTheme()
  const navigation = useNavigation()
  const dispatch = useDispatch()

  const token = useSelector((state: Reducer) => state.auth.token)
  const workSpace = useSelector(
    (state: Reducer) => state.auth.workSpace
  )
  const asipCostLevel = useSelector(
    (state: Reducer) => state.user.ASIPCostLevel
  )
  const currency = useSelector(
    (state: Reducer) => state.user.currency.ID
  )

  const allOpen = useSelector(
    (state: Reducer) => state.settings.analytic17.allOpen
  )
  const priceList = useSelector(
    (state: Reducer) => state.settings.analytic17.priceList
  )
  const useBoMEngineering = useSelector(
    (state: Reducer) => state
      .settings
      .analytic17
      .useBoMEngineering
  )
  const useBoMExternalCosts = useSelector(
    (state: Reducer) => state
      .settings
      .analytic17
      .useBoMExternalCosts
  )
  const useBoMSpecialMaterial = useSelector(
    (state: Reducer) => state
      .settings
      .analytic17
      .useBoMSpecialMaterial
  )
  const showContract = useSelector(
    (state: Reducer) => state.settings.analytic17.showContract
  )
  const useHoursCost = useSelector(
    (state: Reducer) => state.settings.analytic17.useHoursCost
  )

  const [loading, setLoading] = useState<boolean>(false)
  const [year, setYear] = useState<string>(
    new Date().getFullYear().toString()
  )
  const [month, setMonth] = useState<string>(
    (new Date().getMonth() + 1).toString()
  )
  const [beginDate, setBeginDate] = useState<Date | undefined>()
  const [endDate, setEndDate] = useState<Date | undefined>()

  const yearError = useMemo(() => {
    const n = Number(year)
    if (n < 1970 || n > 2100 || isNaN(n)) {
      return t('INVALID_YEAR')
    }
    return undefined
  }, [year])
  
  const asipPriceLevels = useMemo(
    () => getAsipCostLevels(asipCostLevel),
    [asipCostLevel],
  )

  const selectedUseHoursCost = useMemo(() => {
    if (useHoursCost != -1 && useHoursCost != -2) {
      return -3
    }
    return useHoursCost
  }, [useHoursCost])

  const _back = () => navigation.goBack()

  const _year = (text: string) => {
    if (loading) {
      return
    }
    let n = Number(text)
    if (isNaN(n)) {
      return
    }

    setYear(text)
  }

  const _month = (text: string) => {
    if (loading) {
      return
    }
    let n = Number(text)
    if (isNaN(n)) {
      return
    }
    if (n < 1 || n > 12) {
      return
    }

    setMonth(text)
  }

  const _allOpen = (value: boolean) => {
    dispatch(setAnalytic17ConfigAllOpen(value))
  }

  const _priceList = (value: number) => {
    dispatch(setAnalytic17ConfigPriceList(value))
  }

  const _useBoMEngineering = (value: boolean) => {
    dispatch(setAnalytic17ConfigUseBomEngineering(value))
  }

  const _useBoMExternalCosts = (value: boolean) => {
    dispatch(setAnalytic17ConfigUseBomExternalCosts(value))
  }

  const _useBoMSpecialMaterial = (value: boolean) => {
    dispatch(setAnalytic17ConfigUseBomSpecialMaterial(value))
  }

  const _useHoursCosts = (value: number | string) => {
    if (typeof value == 'string') {
      value = Number(value)
    }
    if (isNaN(value)) {
      return
    }
    if (value == -3) {
      dispatch(setAnalytic17ConfigUseHoursCost(0))
    } else {
      dispatch(setAnalytic17ConfigUseHoursCost(value))
    }
  }

  const _showContract = (value: boolean) => {
    dispatch(setAnalytic17ConfigShowContract(value))
  }

  const _download = () => {
    if (loading || yearError != undefined) {
      return
    }

    setLoading(true)
    downloadAnalytic17(
      workSpace?.secondaryUrl || '',
      workSpace?.name || '',
      token,
      {
        year: Number(year),
        month: Number(month),
        beginDate,
        endDate,
        allOpen,
        priceList,
        useBoMEngineering,
        useBoMExternalCosts,
        useBoMSpecialMaterial,
        useHoursCost,
        showContract,
      },
      setLoading,
    )
  }

  return (
    <View style={{flex: 1}}>
      <View style={{
        flexDirection: 'row',
        padding: 10,
        alignItems: 'center',
      }}>
        <MaterialCommunityIcons
          name='close'
          size={44}
          color='black'
          onPress={_back}
        />

        <View style={{paddingLeft: 10}}>
          <Text style={{
            fontSize: 24,
          }}>
            {t('CONSTRUCTION_SITES_CHECK')}
          </Text>
          <Text>{t('ANALYTIC')} 17</Text>
        </View>
      </View>

      <KeyboardAwareScrollView style={{flex: 1}}>
        <View style={{
          padding: 10,
          flexDirection: 'row',
        }}>
          <View style={{flex: 2}}>
            <Text style={{fontSize: 18, marginTop: 5}}>
              {t('EXECUTION_MONTH')}:
            </Text>
            <Text>{t('ANALYTIC_17_EXECUTION_MONTH_HELP')}</Text>
          </View>

          <Label label='MONTH' />

          <View style={{flex: 1}}>
            <TextInput
              value={year}
              onChangeText={_year}
              style={{
                fontSize: 18,
                borderColor: theme.border,
                borderWidth: 1,
                borderRadius: 5,
                padding: 5,
              }}
            />
            {yearError && (
              <Text style={{color: 'red', paddingTop: 5}}>
                {yearError}
              </Text>
            )}
          </View>

          <Label label='MONTH' />

          <TextInput
            value={month}
            onChangeText={_month}
            inputMode='numeric'
            style={{
              fontSize: 18,
              flex: 1,
              borderColor: theme.border,
              borderWidth: 1,
              borderRadius: 5,
              padding: 5,
              alignSelf: 'flex-start',
            }}
          />
        </View>

        <Separator />

        <View style={{
          flexDirection: 'row',
          alignItems: 'flex-start',
          padding: 10,
        }}>
          <View style={{flex: 2}}>
            <Text style={{fontSize: 18}}>
              {t('DATE_RANGE')}:
            </Text>
            <Text>
              {t('ANALYTIC_17_DATE_RANGE_HELP')}
            </Text>
          </View>

          <Label label={'BEGIN_DATE'} />

          <View style={{flex: 1}}>
            <OptionalDate
              date={beginDate}
              onChange={setBeginDate}
              disabled={loading}
            />
          </View>

          <Label label={'END_DATE'} />

          <View style={{flex: 1}}>
            <OptionalDate
              date={endDate}
              onChange={setEndDate}
              disabled={loading}
            />
          </View>
        </View>

        <Separator />

        <View style={{
          flexDirection: 'row',
          alignItems: 'center',
          padding: 10,
        }}>
          <Label label='ALL_OPEN'/>

          <View style={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            paddingTop: 10,
          }}>
            <Switch
              value={allOpen}
              onValueChange={_allOpen}
              disabled={loading}
              style={{marginRight: 10}}
            />
            <HelpIconButton
              content='ANALYTIC_17_SELECT_ALL_OPEN_HELP'
            />
          </View>

          <Label label='ANALYTIC_17_USE_BOM_ENGINEERING'/>

          <View style={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            paddingTop: 10,
          }}>
            <Switch
              value={useBoMEngineering}
              onValueChange={_useBoMEngineering}
              disabled={loading}
              style={{marginRight: 10}}
            />
            <HelpIconButton
              content='ANALYTIC_17_USE_BOM_ENGINEERING_HELP'
            />
          </View>

          <Label label='ANALYTIC_17_USE_BOM_SPECIAL_MATERIALS'/>

          <View style={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            paddingTop: 10,
          }}>
            <Switch
              value={useBoMSpecialMaterial}
              onValueChange={_useBoMSpecialMaterial}
              disabled={loading}
              style={{marginRight: 10}}
            />
            <HelpIconButton
              content='ANALYTIC_17_USE_BOM_SPECIAL_MATERIALS_HELP'
            />
          </View>
        </View>

        <Separator />

        <View style={{
          flexDirection: 'row',
          alignItems: 'center',
          padding: 10,
          marginBottom: 5,
        }}>
          
          <Label label='ANALYTIC_17_USE_BOM_EXTERNAL_COSTS'/>

          <View style={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            paddingTop: 10,
          }}>
            <Switch
              value={useBoMExternalCosts}
              onValueChange={_useBoMExternalCosts}
              disabled={loading}
              style={{marginRight: 10}}
            />
            <HelpIconButton
              content='ANALYTIC_17_USE_BOM_EXTERNAL_COSTS_HELP'
            />
          </View>
          
          <Label label='ANALYTIC_17_SHOW_CONTRACT'/>

          <View style={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            paddingTop: 10,
          }}>
            <Switch
              value={showContract}
              onValueChange={_showContract}
              disabled={loading}
              style={{marginRight: 10}}
            />
            <HelpIconButton
              content='ANALYTIC_17_SHOW_CONTRACT_HELP'
            />
          </View>

          <View style={{flex: 2}}/>
        </View>

        <Separator />

        <View style={{
          padding: 10,
          flexDirection: 'row',
          alignItems: 'center',
        }}>
          <Text style={{
            fontSize: 20,
            paddingRight: 20,
            flex: 1,
          }}>
            {t('PRICE_LIST')}:
          </Text>
      
          <View style={{flex: 4}}>
            <FlatChoose
              itemToKey={i => i.toString()}
              options={asipPriceLevels}
              itemToString={i => i.toString()}
              onChange={_priceList}
              size='medium'
              value={priceList}
            />
          </View>
          <View style={{flex: 2, paddingLeft: 40}} />
        </View>

        <Separator />

        <View style={{
          padding: 10,
          flexDirection: 'row',
          alignItems: 'center',
        }}>
          <Text style={{
            fontSize: 20,
            paddingRight: 20,
            flex: 1,
          }}>
            {t('HOUR_COST')}:
          </Text>

          <View style={{flex: 4}}>
            <FlatChoose
              itemToKey={i => i.toString()}
              options={[-2, -1, -3]}
              itemToString={getAnalytic17HourTypeText}
              onChange={_useHoursCosts}
              size='medium'
              value={selectedUseHoursCost}
            />
          </View>

          {selectedUseHoursCost == -3 ? (
            <TextInput
              value={useHoursCost.toString()}
              onChangeText={_useHoursCosts}
              inputMode='numeric'
              style={{
                fontSize: 18,
                flex: 1,
                borderColor: theme.border,
                borderWidth: 1,
                borderRadius: 5,
                padding: 5,
              }}
            />
          ) : (
            <View style={{flex: 1, padding: 6}}/>
          )}

          {selectedUseHoursCost == -3 ? (
            <Text style={{paddingLeft: 10, flex: 1}}>
              {currency} / h
            </Text>
          ) : (
            <View style={{flex: 1, paddingLeft: 10}}/>
          )}

          <View style={{width: 20}} />
        </View>

        <View style={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}>
          <Button
            onPress={_download}
            disabled={loading || yearError != undefined}
            style={{
              backgroundColor: theme.blue,
              margin: 10,
            }}
          >
            {loading ? (
              <ActivityIndicator color='white' />
            ) : (
              <MaterialCommunityIcons
                name='file-table'
                size={24}
                color='white'
              />
            )}
          </Button>
        </View>
      </KeyboardAwareScrollView>
    </View>
  )
}

const Label = ({label}: {label: string}) => (
  <Text style={{
    flex: 1,
    paddingTop: 5,
    paddingRight: 10,
    fontSize: 18,
    textAlign: 'right',
  }}>
    {t(label)}:
  </Text>
)

const Separator = () => (
  <View style={{
    marginHorizontal: 10,
    borderBottomColor: getTheme().border,
    borderBottomWidth: 1,
  }}/>
)

export default Analytic17Screen
