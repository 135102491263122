import { MaterialCommunityIcons } from '@expo/vector-icons'
import React, { useState } from 'react'
import { Text, View } from 'react-native'
import Modal from 'react-native-modal'
import { t } from 'i18n-js'

interface HelpIconButtonProps {
  content: string
}

const HelpIconButton = ({content}: HelpIconButtonProps) => {

  const [show, setShow] = useState<boolean>(false)
  const _toggle = () => setShow(!show)

  return (
    <>
      <MaterialCommunityIcons
        name='help-circle'
        size={24}
        color='black'
        onPress={_toggle}
      />
      {show && (
        <Modal
          isVisible={show}
          onBackdropPress={_toggle}
          style={{
            width: 400,
            backgroundColor: 'white',
            alignSelf: 'center',
            padding: 15,
            borderRadius: 15,
            height: 400,
            maxHeight: 400,
          }}
        >
          <View style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}>
            <Text style={{fontSize: 30, flex: 1}}>
              {t('HELP')}
            </Text>
            <MaterialCommunityIcons
              name='close'
              size={34}
              color='black'
              onPress={_toggle}
            />
          </View>
          <View style={{flex: 1, paddingTop: 15}}>
            <Text>{t(content)}</Text>
          </View>
        </Modal>
      )}
    </>
  )
}

export default HelpIconButton
