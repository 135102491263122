import { User } from "../../models";
import { GET_USER_SUCCESS, LOGOUT } from "../types";

const initialState: User = {
  name: "",
  username: "",
  profileImage: "",
  company: "",
  branch: {
    ID: 0,
    Name: "",
    Maps: "",
  },
  currency: {
    ID: "",
    Name: "",
  },
  language: {
    ID: 0,
    Name: "",
    Image: "",
  },
  permissions: {
    priceLevel: 0,
    sales: false,
    salesManageBusinessPartners: 0,
    salesDiscount: 0,
    salesUnderMinimumPrice: 0,
    salesChangeUoM: false,
    salesFillBoMVariable: false,
    salesCreateCustomBom: false,
    salesChangePrice: false,
    documentAddFile: false,
    orderUpdate: false,
    resourceAssign: 0,
    resourceAssignView: 0,
    reportCreate: 0,
    reportApprove: 0,
    reportUpdate: 0,
    workAddActivity: false,
    workClose: false,
    invoiceView: 0,
    invoiceCreate: 0,
    reportClientConfirmation: 1,
  },
  readLevel: {
    ID: 1,
    Name: "Guest",
    Level: 0,
  },
  ShowDayReports: 10,
  error: "",
  ReportEditTimeLimit: 0,
  ReportCreateLimitFromWeekStart: 0,
  reportActivitiesLinesFilter: [],
  analytics: {
    enabled: false,
    stat17: false,
  },
};

export default function (
  state = initialState,
  action: { type: string; payload: any },
): User {
  switch (action.type) {
    case GET_USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
