import { Platform } from 'react-native'
import { t } from 'i18n-js'
import { sentryCapture } from '../../../utils/sentryCapture'
import { alert, alertText } from '../../../hooks/Alert'
import * as FileSystem from 'expo-file-system'
import { shareAsync } from 'expo-sharing'

interface Analytic17Config {
  year: number
  month: number
  beginDate?: Date
  endDate?: Date
  allOpen: boolean
  priceList: number
  useBoMEngineering: boolean
  useBoMExternalCosts: boolean
  useBoMSpecialMaterial: boolean
  useHoursCost: number
  showContract: boolean
}

const buildUrl = (baseUrl: string, workSpace: string, token: string, config: Analytic17Config) => {
  let url = `${baseUrl}analytics/analytic17`
  url += `?token=${encodeURIComponent(token)}`
  url += `&workspace=${workSpace}`

  url += `&year=${config.year}`
  url += `&month=${config.month}`
  if (config.beginDate) {
    url += `&beginDate=${config.beginDate.toISOString()}`
  }
  if (config.endDate) {
    url += `&endDate=${config.endDate.toISOString()}`
  }
  url += `&allOpen=${config.allOpen}`
  url += `&priceList=${config.priceList}`
  url += `&useBoMEngineering=${config.useBoMEngineering}`
  url += `&useBoMExternalCosts=${config.useBoMExternalCosts}`
  url += `&useBoMSpecialMaterial=${config.useBoMSpecialMaterial}`
  url += `&useHoursCost=${config.useHoursCost}`
  url += `&showContract=${config.showContract}`

  return url
}

const buildFileName = () => {
  const analyticName = t('CONSTRUCTION_SITES_CHECK').toLowerCase().replace(' ', '-')

  const today = new Date()
  const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`

  return `${t('ANALYTIC').toLowerCase()}-17-${analyticName}-${date}.xlsx`
}

export const downloadAnalytic17 = (
  baseUrl: string,
  workSpace: string,
  token: string,
  config: Analytic17Config,
  setLoading: (loading: boolean) => void,
) => {
  setLoading(true)

  const url = buildUrl(baseUrl, workSpace, token, config)
  const fileName = buildFileName()

  if (Platform.OS == 'web') {
    let anchor = document.createElement('a')
    document.body.appendChild(anchor)

    let headers = new Headers()
    headers.append('Authorization', token)

    fetch(url, { headers })
      .then(response => response.blob())
      .then(blobby => {
        let objectUrl = window.URL.createObjectURL(blobby)

        anchor.href = objectUrl
        anchor.download = fileName
        anchor.click()

        window.URL.revokeObjectURL(objectUrl)
      }).catch(e => {
        sentryCapture(e)
        alert('ERROR', 'ERROR_SAVING_ANALYTIC')
      }).finally(() => setLoading(false))
  } else {
    FileSystem.downloadAsync(
      url,
      FileSystem.documentDirectory + fileName,
      { headers: { 'Authorization': token } }
    ).then(async (file) => {
      if (file.status != 200) {
        alert('ERROR', 'ERROR_SAVING_ANALYTIC')
        sentryCapture(file)
        return
      }
      if (Platform.OS == 'ios') {
        shareAsync(file.uri)
      } else if (Platform.OS == 'android') {
        alertText('NOT_IMPLEMENTED', 'Feature not implemented on Android (Reference #844).')
      }
    }).catch(e => {
      console.log('error', e);
      sentryCapture(e)
      alert('ERROR', 'ERROR_SAVING_ANALYTIC')
    }).finally(() => setLoading(false))
  }
}
