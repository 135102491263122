import React, { useMemo, useState } from 'react'
import { AccountingDocumentType, ConstructionSite, Contract, Measure } from "../../../../models";
import { t } from "i18n-js";
import { SearchScreens } from "../../../search/SearchScreens";
import { useNavigation } from "@react-navigation/native";
import { ActivityIndicator, FlatList, Text, View, Pressable } from "react-native";
import { alertText } from "../../../../hooks/Alert";
import { useSelector } from "react-redux";
import getTheme from "../../../../constants/Colors";
import Button from "../../../../components/Button";
import { FontAwesome6, MaterialCommunityIcons } from "@expo/vector-icons";
import { Reducer } from "../../../../store/reducers";
import { prepareMeasure } from '../../../../api/MeasureAPI'
import { downloadWorkProggressStatusNew, setOfflineMeasures } from '../../../../utils/measure/util'
import MeasuresHelpModal from './MeasuresHelpModal';

interface Props {
  constructionSite: ConstructionSite;
  contract: Contract;
  setInvoice: (invoiceId: number, type: AccountingDocumentType) => void;
}

const Measures = ({ contract, constructionSite, setInvoice }: Props) => {

  const navigation = useNavigation();
  const theme = getTheme();

  const token = useSelector((state: Reducer) => state.auth.token);
  const workSpace = useSelector((state: Reducer) => state.auth.workSpace)

  const [help, setHelp] = useState<boolean>(false);
  const _help = () => setHelp(h => !h);

  const [workProgressStatusLoading, setWorkProgressStatusLoading] = useState<boolean>(false);
  const [createProgress, setCreateProgress] = useState<number>()

  const measures = useMemo(
    () => contract.measures.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime()),
    [contract.measures],
  )

  const _create = async () => {
    setCreateProgress(0.005)
    const measure = await prepareMeasure(contract, constructionSite, setCreateProgress)
    const uuid = await setOfflineMeasures(measure)
    setCreateProgress(undefined)
    // @ts-ignore
    navigation.navigate(SearchScreens.MEASURE_CREATE, {
      uuid,
    })
  }

  const _workProggressStatus = () => {
    if (workSpace) {
      downloadWorkProggressStatusNew(
        workSpace,
        token,
        contract.ID,
        constructionSite.Code,
        setWorkProgressStatusLoading,
      )
    }
  }

  const _render = ({ item, index }: { item: Measure, index: number }) => {
    // @ts-ignore
    const _press = () => navigation.navigate(SearchScreens.MEASURE_VIEW, {
      id: item.id,
      constructionSite,
      contract,
    });

    const creditNote = contract
      .Docs
      .filter(d => d.Type.Config.AccountingDocument == AccountingDocumentType.CreditNote)
      .filter(d => d.invoiceId == item.invoiceId)
    const hasCreditNote = creditNote.length > 0

    const _invoice = () => {
      if (item.invoiceId) {
        if (hasCreditNote) {
          setInvoice(creditNote[0].ID, AccountingDocumentType.CreditNote)
        } else {
          setInvoice(item.invoiceId, AccountingDocumentType.Invoice)
        }
      }
    }

    const _download = () => alertText('NOT_IMPLEMENTED')

    return (
      <Pressable
        onPress={_press}
        style={{
          borderTopColor: theme.border,
          borderTopWidth: 1,
          paddingVertical: 10,
          flexDirection: 'row',
          alignItems: 'center'
        }}
        key={`measures-${contract.ID}-${index}-${item.id}`}
      >
        <Text style={{ width: 50 }}>{item.number || ''}</Text>
        <Text style={{ flex: 1 }}>{item.createdAt.toLocaleDateString()}</Text>
        <Text style={{ flex: 2 }}>{item.createdBy.FirstName} {item.createdBy.LastName}</Text>

        <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
          <MaterialCommunityIcons
            name='file-document'
            color='black'
            size={20}
            style={{ marginRight: 5 }}
          />
          <Text>{item.DayReports?.length}</Text>
        </View>

        {item.invoiceId ? (
          <FontAwesome6
            name={hasCreditNote ? 'file-circle-minus' : 'file-invoice-dollar'}
            color='black'
            size={20}
            style={{
              paddingRight: hasCreditNote ? 5 : 10,
              paddingLeft: hasCreditNote ? 7 : 10,
            }}
            onPress={_invoice}
          />
        ) : (
          <View style={{ width: 30 }} />
        )}

        <MaterialCommunityIcons
          name='download'
          color='black'
          size={24}
          onPress={_download}
          style={{ paddingHorizontal: 10 }}
        />
      </Pressable>
    )
  }

  return (
    <View style={{ paddingBottom: 120, paddingLeft: 10, flex: 1 }}>
      {help && <MeasuresHelpModal onClose={_help} />}
      <View style={{ flexDirection: 'row' }}>
        <Text style={{ flex: 1, fontSize: 22, fontWeight: 'bold', alignSelf: 'center' }}>{t("MEASURES")}</Text>

        <MaterialCommunityIcons
          name='help-circle'
          size={24}
          color='black'
          style={{ alignSelf: 'center', paddingHorizontal: 10 }}
          onPress={_help}
        />

        <Button
          onPress={_workProggressStatus}
          style={{
            backgroundColor: theme.blue,
            alignItems: 'center',
            justifyContent: 'center',
          }}
          disabled={workProgressStatusLoading}
        >
          {workProgressStatusLoading ? <ActivityIndicator /> : <MaterialCommunityIcons name="download" color="white" size={22} />}
        </Button>
        <Button
          onPress={_create}
          style={{
            backgroundColor: theme.blue,
          }}
          disabled={createProgress != undefined}
        >
          {(createProgress != undefined) ? (
            <ActivityIndicator color='white' />
          ) : (
            <MaterialCommunityIcons name="plus" color="white" size={22} />
          )}
        </Button>
      </View>

      {(createProgress != undefined) && (
        <View style={{ flexDirection: 'row', paddingTop: 10 }}>
          <View style={{ flex: (createProgress * 100), backgroundColor: theme.mainColor, height: 1 }} />
          <View style={{ flex: (1 - createProgress) * 100, height: 2 }} />
        </View>
      )}

      <View style={{ flexDirection: 'row', paddingVertical: 10 }}>
        <Text style={{ width: 50, fontWeight: 'bold' }}>N&deg;</Text>
        <Text style={{ flex: 1, fontWeight: 'bold' }}>{t("DATE")}</Text>
        <Text style={{ flex: 2, fontWeight: 'bold' }}>{t("APPROVED_BY")}</Text>
        <Text style={{ flex: 1, fontWeight: 'bold' }}>{t("DAY_REPORTS")}</Text>
        <View style={{ width: 85 }} />
      </View>

      <FlatList
        style={{ paddingRight: 5 }}
        data={measures}
        renderItem={_render}
        key={`measures-${contract.ID}`}
        // @ts-ignore
        listKey={`measures-${contract.ID}`}
        keyExtractor={(item, index) => `measures-${contract.ID}-${index}-${item.id}`}
      />
    </View>
  );
};

export default Measures