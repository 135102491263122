import { AccountingDocumentType, Measure, OfflineMeasure, WorkSpace } from '../../models'
import { round } from '../../shared-utils/NumberUtils'
import { v4 } from 'uuid'
import { Platform } from 'react-native'
import { sentryCapture } from '../sentryCapture'
import { alert, alertText } from '../../hooks/Alert'
import * as FileSystem from 'expo-file-system'
import { shareAsync } from 'expo-sharing'
import storage from '../../storage'

export const setApprovableQuantity = (
  measure: Measure,
  quantity: number,
  levelIndex: number,
  measureLineIndex: number
): Measure => {
  const level =
    measure.measureLines[measureLineIndex].Levels?.[levelIndex].Level

  const levelsQuantity = measure.measureLines[
    measureLineIndex
  ].MeasureReports.reduce(
    (p, v) =>
      p +
      (v.Report.Levels?.reduce((p, v) => {
        if (v.LevelID === level?.ID) {
          return p + (v.ApprovedQuantity || 0)
        }
        return p
      }, 0) || 0),
    0
  )

  const approvable = measure.measureLines.reduce(
    (p, measureLine, measureLineIndexLoop) => {
      if (measureLineIndexLoop === measureLineIndex) {
        return (
          p +
          measureLine.MeasureReports.reduce(
            (p, measureReport) =>
              p +
              (measureReport.Report.Levels?.reduce(
                (p, measureReportLevel, measureReportLevelIndex) => {
                  if (measureReportLevelIndex == levelIndex) {
                    return (
                      p +
                      round(
                        (quantity / levelsQuantity) *
                          measureReportLevel.Quantity,
                        2
                      )
                    )
                  }
                  return p
                },
                0
              ) || 0),
            0
          )
        )
      }
      return p
    },
    0
  )
  const diff = quantity - approvable

  const check = measure.measureLines.reduce(
    (p, measureLine, measureLineIndexLoop) => {
      if (measureLineIndexLoop === measureLineIndex) {
        return (
          p +
          measureLine.MeasureReports.reduce(
            (p, measureReport) =>
              p +
              (measureReport.Report.Levels?.reduce(
                (p, measureReportLevel, measureReportLevelIndex) => {
                  if (measureReportLevelIndex == levelIndex) {
                    return p + measureReportLevel.Quantity
                  }
                  return p
                },
                0
              ) || 0),
            0
          )
        )
      }
      return p
    },
    0
  )
  if (check == 0) {
    return {
      ...measure,
      measureLines: measure.measureLines.map(
        (measureLine, measureLineIndexLoop) => {
          if (measureLineIndexLoop === measureLineIndex) {
            return {
              ...measureLine,
              ApprovableQuantity: approvable,
              BillableQuantity: quantity,
              MeasureReports: measureLine.MeasureReports.map(
                (measureReport) => ({
                  ...measureReport,
                  Report: {
                    ...measureReport.Report,
                    Levels: measureReport.Report.Levels?.map(
                      (measureReportLevel, measureReportLevelIndex) => {
                        if (measureReportLevelIndex == levelIndex) {
                          const dayReportsWithActivity =
                            measure.DayReports.filter(
                              (d) =>
                                (d.Reports?.filter(
                                  (r) => r.ActivityID === measureLine.ActivityID
                                ).length || 0) > 0
                            )
                          return {
                            ...measureReportLevel,
                            ApprovedQuantity: round(
                              (quantity / dayReportsWithActivity.length),
                              2
                            ),
                            BillableQuantity: round(
                              (quantity / dayReportsWithActivity.length),
                              2
                            ),
                          }
                        }
                        return measureReportLevel
                      }
                    ),
                  },
                })
              ),
            }
          }
          return measureLine
        }
      ),
    }
  }

  return {
    ...measure,
    measureLines: measure.measureLines.map(
      (measureLine, measureLineIndexLoop) => {
        if (measureLineIndexLoop === measureLineIndex) {
          const approvableQuantity = measureLine.MeasureReports.reduce(
            (p, measureReport, measureReportIndex) =>
              p +
              (measureReport.Report.Levels?.reduce(
                (p, measureReportLevel, measureReportLevelIndex) => {
                  if (measureReportLevelIndex == levelIndex) {
                    return (
                      p +
                      round(
                        (quantity / levelsQuantity) *
                          measureReportLevel.Quantity,
                        2
                      ) +
                      (measureLine.MeasureReports.length - 1 ===
                      measureReportIndex
                        ? diff
                        : 0)
                    )
                  }
                  return p
                },
                0
              ) || 0),
            0
          )
          return {
            ...measureLine,
            ApprovableQuantity: approvableQuantity,
            BillableQuantity: approvableQuantity,
            MeasureReports: measureLine.MeasureReports.map(
              (measureReport, measureReportIndex) => ({
                ...measureReport,
                Report: {
                  ...measureReport.Report,
                  Levels: measureReport.Report.Levels?.map(
                    (measureReportLevel, measureReportLevelIndex) => {
                      if (measureReportLevelIndex == levelIndex) {
                        return {
                          ...measureReportLevel,
                          ApprovedQuantity: round(
                            (quantity / levelsQuantity) *
                              measureReportLevel.Quantity,
                            2
                          ),
                          BillableQuantity: round(
                            (quantity / levelsQuantity) *
                              measureReportLevel.Quantity,
                            2
                          ),
                        }
                      }
                      return measureReportLevel
                    }
                  ),
                },
              })
            ),
          }
        }
        return measureLine
      }
    ),
  }
}

const getMeasureStorageKey = (uuid: string): string => {
  return `measure-${uuid}`
}

const OFFLINE_MEASURE_LIST = 'offline-measure-list'

export const setOfflineMeasures = async (measure: Measure, uuid?: string): Promise<string> => {
  const localUuid = uuid || v4()

  const offlineMeasure: OfflineMeasure = {
    uuid: localUuid,
    constructionSiteCode: measure.constructionSite.Code,
    constructionSiteName: measure.constructionSite.Name,
    contractName: measure.contract.Name,
    date: new Date(),
  }

  await storage.setItem(
    getMeasureStorageKey(localUuid),
    JSON.stringify(measure)
  )
  const d = await storage.getItem(OFFLINE_MEASURE_LIST)
  let offlineMeasures: OfflineMeasure[] = d ? JSON.parse(d) : []

  if (offlineMeasures.find((m) => m.uuid === localUuid)) {
    offlineMeasures.push(offlineMeasure)
  } else {
    const index = offlineMeasures.findIndex((m) => m.uuid === localUuid)
    offlineMeasures[index] = offlineMeasure
  }
  await storage.setItem(
    OFFLINE_MEASURE_LIST,
    JSON.stringify(offlineMeasures)
  )

  return localUuid
}

export const removeOfflineMeasure = async (uuid: string): Promise<void> => {
  await storage.removeItem(getMeasureStorageKey(uuid))
  const d = await storage.getItem(OFFLINE_MEASURE_LIST)
  const offlineMeasures: OfflineMeasure[] = d ? JSON.parse(d) : []
  const newOfflineMeasures = offlineMeasures.filter((m) => m.uuid !== uuid)
  await storage.setItem(
    OFFLINE_MEASURE_LIST,
    JSON.stringify(newOfflineMeasures)
  )
}

export const getOfflineMeasures = async (): Promise<OfflineMeasure[]> => {
  const d = await storage.getItem(OFFLINE_MEASURE_LIST)
  const json = JSON.parse(d || '[]')

  return json.map((m: OfflineMeasure) => ({
    ...m,
    date: new Date(m.date),
  }))
}

export const getMeasureByStorageKey = async (
  uuid: string
): Promise<Measure> => {
  const data = await storage.getItem(getMeasureStorageKey(uuid))
  if (!data) {
    throw new Error('Measure not found')
  }
  const measure: Measure = JSON.parse(data)
  return {
    ...measure,
    createdAt: new Date(measure.createdAt),
    DayReports: measure.DayReports.map((dr) => ({
      ...dr,
      DateTime: new Date(dr.DateTime),
      SignedTime: dr.SignedTime ? new Date(dr.SignedTime) : undefined,
    })),
    measureLines: measure.measureLines.map((ml) => ({
      ...ml,
      MeasureReports: ml.MeasureReports.map((mr) => ({
        ...mr,
        DayReport: {
          ...mr.DayReport,
          DateTime: new Date(mr.DayReport.DateTime),
          SignedTime: mr.DayReport.SignedTime
            ? new Date(mr.DayReport.SignedTime)
            : undefined,
        },
      })),
    })),
  }
}

/**
 * Get the credit note id for the invoce of the measure.
 * @param measure Measure.
 * @returns The credit note id of the invoice of the measure if exists, otherwise false.
 */
export const getMeasureInvoiceCreditNoteId = (
  measure: Measure
): number | undefined => {
  return measure.invoices.find(
    (i) =>
      i.Type.Config.AccountingDocument == AccountingDocumentType.CreditNote &&
      i.invoiceId == measure.invoiceId
  )?.ID
}

/**
 * Can unapprove if is not yet invoiced or if the invoice has a credit note.
 * @param measure Measure.
 * @returns True if can unapprove, otherwise false.
 */
export const canUnapproveMeasure = (measure: Measure): boolean => {
  return (
    measure.invoiceId == undefined ||
    getMeasureInvoiceCreditNoteId(measure) != undefined
  )
}

export const downloadWorkProggressStatusNew = (
  workSpace: WorkSpace,
  token: string,
  contractId: number,
  constructionSiteCode: string,
  setLoading: (loading: boolean) => void,
) => {
  setLoading(true)

  let file = `${workSpace.secondaryUrl}work-progress-status`
  file += `?workspace=${workSpace.name}`
  file += `&token=${encodeURIComponent(token)}`
  file += `&contractId=${contractId}`

  const fileName = `${constructionSiteCode}-misure.xlsx`
  if (Platform.OS == "web") {
    let anchor = document.createElement("a")
    document.body.appendChild(anchor)

    fetch(file)
      .then(response => response.blob())
      .then(blobby => {
        let objectUrl = window.URL.createObjectURL(blobby)

        anchor.href = objectUrl
        anchor.download = fileName
        anchor.click()

        window.URL.revokeObjectURL(objectUrl)
      }).catch(e => {
        sentryCapture(e)
        alert('ERROR', 'ERROR_SAVING_WORK_PROGRESS_STATUS')
      }).finally(() => setLoading(false))
  } else {
    FileSystem.downloadAsync(
      file,
      FileSystem.documentDirectory + fileName,
      { headers: { 'Authorization': token } }
    ).then(async (file) => {
      if (Platform.OS == 'ios') {
        shareAsync(file.uri)
      } else if (Platform.OS == 'android') {
        alertText('NOT_IMPLEMENTED', 'Feature not implemented on Android (Reference #844).')
      }
    }).catch(e => {
      console.log('error', e);
      sentryCapture(e)
      alert('ERROR', 'ERROR_SAVING_WORK_PROGRESS_STATUS')
    }).finally(() => setLoading(false))
  }
}

export const downloadIndustrialAccounting = (
  url: string,
  workSpace: string,
  token: string,
  contractId: number,
  constructionSiteCode: string,
  setLoading: (loading: boolean) => void,
) => {
  setLoading(true)

  const file = `${url}industrial-accounting?token=${encodeURIComponent(token)}&workspace=${workSpace}&contractId=${contractId}`
  const fileName = `${constructionSiteCode}-contabilita-industriale.pdf`
  if (Platform.OS == "web") {
    let anchor = document.createElement("a")
    document.body.appendChild(anchor)

    let headers = new Headers()
    headers.append('Authorization', token)

    fetch(file, { headers })
      .then(response => response.blob())
      .then(blobby => {
        let objectUrl = window.URL.createObjectURL(blobby)

        anchor.href = objectUrl
        anchor.download = fileName
        anchor.click()

        window.URL.revokeObjectURL(objectUrl)
      }).catch(e => {
        sentryCapture(e)
        alert('ERROR', 'ERROR_SAVING_WORK_PROGRESS_STATUS')
      }).finally(() => setLoading(false))
  } else {
    FileSystem.downloadAsync(
      file,
      FileSystem.documentDirectory + fileName,
      { headers: { 'Authorization': token } }
    ).then(async (file) => {
      if (Platform.OS == 'ios') {
        shareAsync(file.uri)
      } else if (Platform.OS == 'android') {
        alertText('NOT_IMPLEMENTED', 'Feature not implemented on Android (Reference #844).')
      }
    }).catch(e => {
      console.log('error', e);
      sentryCapture(e)
      alert('ERROR', 'ERROR_SAVING_WORK_PROGRESS_STATUS')
    }).finally(() => setLoading(false))
  }
}

export const downloadIndustrialAccountingMultiContracts = (
  url: string,
  workSpace: string,
  token: string,
  contractIds: number[],
  constructionSiteCode: string,
  setLoading: (loading: boolean) => void,
) => {
  setLoading(true)

  const file = `${url}industrial-accounting?token=${encodeURIComponent(token)}&workspace=${workSpace}&contractIds=${contractIds.join(',')}`
  const fileName = `${constructionSiteCode}-contabilita-industriale.pdf`
  if (Platform.OS == "web") {
    let anchor = document.createElement("a")
    document.body.appendChild(anchor)

    let headers = new Headers()
    headers.append('Authorization', token)

    fetch(file, { headers })
      .then(response => response.blob())
      .then(blobby => {
        let objectUrl = window.URL.createObjectURL(blobby)

        anchor.href = objectUrl
        anchor.download = fileName
        anchor.click()

        window.URL.revokeObjectURL(objectUrl)
      }).catch(e => {
        sentryCapture(e)
        alert('ERROR', 'ERROR_SAVING_WORK_PROGRESS_STATUS')
      }).finally(() => setLoading(false))
  } else {
    FileSystem.downloadAsync(
      file,
      FileSystem.documentDirectory + fileName,
      { headers: { 'Authorization': token } }
    ).then(async (file) => {
      if (Platform.OS == 'ios') {
        shareAsync(file.uri)
      } else if (Platform.OS == 'android') {
        alertText('NOT_IMPLEMENTED', 'Feature not implemented on Android (Reference #844).')
      }
    }).catch(e => {
      console.log('error', e);
      sentryCapture(e)
      alert('ERROR', 'ERROR_SAVING_WORK_PROGRESS_STATUS')
    }).finally(() => setLoading(false))
  }
}
