import { configureStore } from '@reduxjs/toolkit'
import thunk from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";

import AsyncStorage from "@react-native-async-storage/async-storage";
import localforage from "localforage";
import { Platform } from "react-native";

import rootReducer from "./reducers";

const persistConfig = {
  key: "@DRYapp1.11",
  storage: Platform.OS === "web" ? localforage : AsyncStorage,
  blacklist: ['sync'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [thunk];

const initialState = {};

const store = configureStore({
  reducer: persistedReducer,
  preloadedState: initialState,
  middleware: middleware,
});

// @ts-ignore
export const persistor = persistStore(store);

export default store;
