import React from 'react'
import getTheme from '../../constants/Colors'
import DatePicker from '../DatePicker'
import { Pressable, Text, View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { t } from 'i18n-js'

const OptionalDate = ({
  date,
  onChange,
  disabled,
}: {
  date: Date | undefined,
  onChange: (date: Date | undefined) => void,
  disabled: boolean,
}) => {
  const theme = getTheme()

  const _select = () => onChange(new Date())

  const _clear = () => onChange(undefined)
  
  return (
    <View style={{
      flexDirection: 'row',
      alignItems: 'center',
    }}>
      {date ? (
        <DatePicker
          value={date}
          onChange={onChange}
          disabled={disabled}
        />
      ) : (
        <Pressable
          onPress={_select}
          style={{
            padding: 5,
            borderColor: theme.border,
            borderWidth: 1,
            borderRadius: 5,
            alignItems: 'flex-start',
            alignSelf: 'flex-start'
          }}
        >
          <Text style={{fontSize: 16}}>
            {t('SELECT')}
          </Text>
        </Pressable>
      )}

      {date && (
        <MaterialCommunityIcons
          size={24}
          color='black'
          name='close'
          onPress={_clear}
          style={{paddingLeft: 10}}
        />
      )}
    </View>
  )
}

export default OptionalDate
