

const MAX_ASIP_COST_LEVEL = 5

export const getAsipCostLevels = (asipCostLevel?: number): number[] => {
  const p = []
  for (let i = (asipCostLevel || MAX_ASIP_COST_LEVEL + 1); i <= MAX_ASIP_COST_LEVEL; i++) {
    p.push(i)
  }
  return p
}