import { useNavigation } from "@react-navigation/native";
import {
  createDrawerNavigator,
  DrawerContentScrollView,
} from "@react-navigation/drawer";

import getTheme from "../constants/Colors";
import { useDispatch, useSelector } from "react-redux";

import { FontAwesome5, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { Reducer } from "../store/reducers";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "./index";
import * as React from "react";
import { SearchScreenStack } from "../screens/search/SearchScreenStack";
import { ResourceStackNavigator } from "../screens/resources/ResourceStackNavigator";
import NoInternetScreen from "../screens/noInternet/NoInternetScreen";
import { ReportsStackNavigator } from "../screens/reports/ReportsStackNavigator";
import { ActivityIndicator, Pressable, View } from "react-native";
import UserScreen from "../screens/user/UserScreen";
import { PriceLevel } from "../store/reducers/settingsReducer";
import PriceLevelSelector from "../components/PriceLevelSelector/PriceLevelSelector";
import { checkNetwork } from "../store/action/syncAction";
import { AnalyticsStack } from "../screens/analytics/AnalyticsStack"

const Drawer = createDrawerNavigator();

const getIcon = (screenName: string, color: string) => {
  switch (screenName) {
    case "StackNavigatorDocument":
      return <MaterialIcons color={color} size={22} name={"add"} />;
    case "OffersScreen":
      return (
        <MaterialIcons
          color={color}
          size={22}
          name={"insert-drive-file"}
        />
      );
    case "ConstructionSitesStack":
      return (
        <MaterialCommunityIcons color={color} size={22} name="crane" />
      );
    case "ReportsScreen":
      return <FontAwesome5 color={color} size={22} name='calendar-alt' />;
    case "ResourceStackNavigator":
      return <MaterialIcons color={color} size={22} name={"people"} />;
    case "SearchScreenStack":
      return (
        <MaterialCommunityIcons
          color={color}
          size={22}
          name={"magnify"}
        />
      );
    case "AnalyticsStack":
      return (
        <MaterialCommunityIcons
          color={color}
          size={22}
          name='chart-line'
        />
      )
    case "UserScreen":
      return (
        <MaterialCommunityIcons
          color={color}
          size={22}
          name='account'
        />
      );
    default:
      return <></>;
  }
};

function CustomDrawerContent(props: any) {
  const theme = getTheme()
  const dispatch = useDispatch()

  const serverReachable = useSelector((state: Reducer) => state.sync.serverReachable)
  const checkingNetwork = useSelector((state: Reducer) => state.sync.checkingNetwork)

  const _checkNetwork = () => dispatch(checkNetwork())

  return (
    <>
      <DrawerContentScrollView {...props} safeArea scrollEnabled={false}>
        {props.state.routeNames.map((name: string, index: number) => (
          <Pressable
            style={{
              borderRadius: 100,
              paddingVertical: 6,
              paddingHorizontal: 6
            }}
            key={index}
            onPress={() => {
              props.navigation.navigate(name);
            }}
          >
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 100,
                borderColor: theme.secondaryBackground,
                borderWidth: 1,
                padding: 11,
                backgroundColor: index === props.state.index ? theme.mainColor : "white"
              }}
            >
              {getIcon(name, index === props.state.index ? "white" : "black")}
            </View>
          </Pressable>
        ))}
      </DrawerContentScrollView>
      
      <PriceLevelSelector />

      <View style={{paddingVertical: 10, backgroundColor: theme.background}}>
        {checkingNetwork ? (
          <View style={{paddingTop: 7, paddingBottom: 8}}>
            <ActivityIndicator color={theme.mainColor} size={20} />
          </View>
        ) : (
          <MaterialCommunityIcons
            name={serverReachable ? 'link-variant' : 'link-variant-off'}
            size={35}
            style={{
              alignSelf: 'center',
            }}
            color={serverReachable ? 'green' : 'red'}
            onPress={_checkNetwork}
          />
        )}
      </View>
    </>
  );
}

export default function Sidebar() {
  const permissions = useSelector((state: Reducer) => state.user.permissions);
  const analyticsEnabled = useSelector((state: Reducer) => state.user.analytics.enabled)
  const serverReachable = useSelector(
    (state: Reducer) => state.sync.serverReachable
  );

  const theme = getTheme();
  const navigation = React.useRef(
    useNavigation<StackNavigationProp<RootStackParamList>>()
  );

  return (
    <Drawer.Navigator
      screenOptions={{
        headerShown: false,
        drawerType: "permanent",
        drawerStyle: {
          width: "auto",
          height: '100%',
          backgroundColor: theme.secondaryBackground,
        },
        sceneContainerStyle: {
          backgroundColor: theme.background,
        },
      }}
      drawerContent={(props) => <CustomDrawerContent {...props} />}
      initialRouteName={
        permissions.sales ? "SearchScreenStack" : (serverReachable?"ReportsScreen":"NoInternetConnection")
      }
    >
      {!serverReachable && (
        <Drawer.Screen
          name={"NoInternetConnection"}
          component={NoInternetScreen}
        />
      )}
      {(serverReachable || permissions.sales) && (
        <Drawer.Screen name="SearchScreenStack" component={SearchScreenStack} />
      )}
      <Drawer.Screen name="ReportsScreen" component={ReportsStackNavigator} />
      {serverReachable && (
        <Drawer.Screen
          name="ResourceStackNavigator"
          component={ResourceStackNavigator}
        />
      )}
      {(serverReachable || analyticsEnabled) && (
        <Drawer.Screen
          name="AnalyticsStack"
          component={AnalyticsStack}
        />
      )}
      <Drawer.Screen name="UserScreen" component={UserScreen} />
    </Drawer.Navigator>
  );
}
